const baseLuxuryKeywords = [
  'lvmh',
  'louis-vuitton',
  'chanel',
  'hermes',
  'dior',
  'gucci',
  'kering',
  'richemont',
  'balenciaga',
  'fendi',
  'versace',
  'burberry',
  'prada',
  'saint-laurent',
  'the-row',
  'bottega-veneta',
  'loewe',
  'celine',
];

const baseSustainabilityKeywords = [
  'csrd',
  'csddd',
  'erp',
  'greenwashing',
  'dpp',
];

const baseResaleKeywords = [
  'vestiaire-collective',
  'resale',
  'reflaunt',
  'rebag',
  'thredup',
  'the-realreal',
  'vinted',
  'sellpy',
];

const baseAnalysisKeywords = [
  'top-100',
  'fashionunited-top-100-index',
];

const globalKeywords = {
  trends: {
    season: [
      'fw21',
      'ss22',
      'fw22',
      'ss23',
      'fw23',
      'ss24',
      'fw24',
      'ss25',
      'fw25',
      'ss26',
      'fw26',
      'ss27',
      'fw27',
      'ss28',
      'fw28',
      'ss29',
      'fw29',
    ],
    womenswear: ['womenswear', 'womenswearcatwalkseason'],
    menswear: ['menswear', 'menswearcatwalkseason'],
    kids: ['kidswear', 'childrenswear'],
    footwear: ['footwear', 'sneakers'],
    denim: ['denim', 'jeans'],
    trends: ['trends'],
    colours: ['pantone', 'colours', 'colors'],
    bodyFashion: [],
    beauty: [],
    parade: [],
    christineBoland: [],
    edited: [],
    trendUnion: [],
    fashionSnoops: [],
    peclers: [],
    trendstop: [],
    forecast: [],
    itemOfTheWeek: ['item-of-the-week'],
    mannenmode: [],
  },
  'fashion-week-web': {
    parisFashionWeek: [
      'paris-fashion-week',
      'pfw',
      'paris-couture-week',
      'paris-fashion-week-men-s',
      'paris-haute-couture-week',
      'paris-men-s-fashion-week',
      'haute-couture-fashion-week',
      'haute-couture',
    ],
    milanFashionWeek: [
      'milan-fashion-week', 'milan-fashion-week-men-s', 'mfw',
    ],
    newYorkFashionWeek: [
      'new-york-fashion-week', 'nyfw', 'new-york-fashion-week-men-s',
    ],
    londonFashionWeek: [
      'london-fashion-week',
      'lfw',
      'london-fashion-week-men',
      'london-fashion-week-men-s',
    ],
    accraFashionWeek: [
      'accra-fashion-week',
    ],
    amsterdamFashionWeek: [
      'amsterdam-fashion-week',
    ],
    arabFashionWeek: [
      'arab-fashion-week',
    ],
    berlinFashionWeek: [
      'mercedes-benz-fashion-week-berlin',
      'berlin-fashion-week',
    ],
    chinaFashionWeek: [
      'china-fashion-week',
    ],
    copenhagenFashionWeek: [
      'copenhagen-fashion-week',
    ],
    fashionRevolutionWeek: [
      'fashion-revolution-week',
    ],
    frankfurtFashionWeek: [
      'frankfurt-fashion-week',
    ],
    graduateFashionWeek: [
      'graduate-fashion-week',
    ],
    lakmeFashionWeek: [
      'lakme-fashion-week', 'indian-fashion-week',
    ],
    losAngelesFashionWeek: [
      'los-angeles-fashion-week',
    ],
    madridFashionWeek: [
      'mercedes-benz-fashion-week-madrid', 'madrid-fashion-week',
    ],
    moscowFashionWeek: [
      'moscow-fashion-week',
    ],
    saoPauloFashionWeek: [
      'sao-paulo-fashion-week',
    ],
    seoulFashionWeek: [
      'seoul-fashion-week',
    ],
    shanghaiFashionWeek: [
      'shanghai-fashion-week',
    ],
    stockholmFashionWeek: [
      'stockholm-fashion-week',
    ],
    taipeiFashionWeek: [
      'taipei-fashion-week',
    ],
    tokyoFashionWeek: [
      'tokyo-fashion-week',
      'japan-fashion-week',
      'rakuten-fashion-week',
      'shibuya-fashion-week',
    ],
    veganFashionWeek: [
      'vegan-fashion-week',
    ],
    moreFashionWeek: [
      'canada-fashion-week',
      'fashion-week-istanbul',
      'dakar-fashion-week',
      'fashion-week',
      'china-fashion-week',
      'bridal-fashion-week',
      'barcelona-fashion-week',
      'fashion-week-sao-paulo',
      'helsinki-fashion-week',
      'havana-fashion-week',
      'jakarta-fashion-week',
      'lisboa-fashion-week',
      'new-zealand-fashion-week',
      'mercedes-benz-fashion-week-tbilisi',
      'vancouver-fashion-week',
    ],
  },
  education: {
    graduation: [
      'graduation-show',
      'graduation',
      'graduation-fashion-show',
      'fashion-graduates',
      'graduate',
      'graduate-fashion-foundation',
    ],
    workInFashion: ['workinfashion', 'wfh', 'career-coach'],
    sustainability: [
      'sustainability',
      'circularity',
      'sustainable-fashion',
      'digital-fashion',
    ],
    influencers: [
      'influencer',
      'influencer-marketing',
      'influencers',
      'top-fashion-influencers',
    ],
    socialMedia: [
      'tiktok',
      'instagram',
      'facebook',
      'youtube',
      'wechat',
      'twitter',
      'clubhouse',
      'whatsapp',
      'snapchat',
    ],
    diversity: [
      'racism',
      'diversity',
      'black-lives-matter',
      'gender',
      'inclusivity',
    ],
    internship: ['internship', 'internships'],
  },
  executive: {
    analysis: [
      ...baseAnalysisKeywords,
      'analysis',
      'index',
      'study',
      'case-study',
    ],
    sustainability: [
      ...baseSustainabilityKeywords,
      'sustainability',
      'circularity',
      'sustainable-fashion',
      'circular-fashion',
    ],
    resale: [
      ...baseResaleKeywords,
      'secondhand-fashion',
    ],
    eCommerce: [
      'ecommerce',
      'e-commerce',
    ],
    supplyChain: [
      'supply-chain',
      'logistics',
      'sourcing',
      'production',
      'workers-rights',
    ],
    luxury: [
      ...baseLuxuryKeywords,
    ],
    verticals: [
      'inditex',
      'zara',
      'hennes-mauritz',
      'h-m',
      'gap',
      'c-a',
      'mango',
      'uniqlo',
      'fast-retailing',
      'pvh',
      'ralph-lauren',
      'tommy-hilfiger',
      'next',
      'american-eagle',
      'abercrombie-fitch',
      'esprit',
      'shein',
      'temu',
    ],
  },
};

const keywords = {
  trends: {
    de: {
      ...globalKeywords.trends,
      womenswear: ['damenmode', ...globalKeywords.trends.womenswear],
      menswear: ['herrenmode', ...globalKeywords.trends.menswear],
      kids: ['kindermode'],
      footwear: ['schuhe', 'sneaker'],
      colours: ['pantone', 'farben'],
      forecast: [
        'edited',
        'lidewij-edelkoort',
        'fashion-snoops',
        'peclers-paris',
        'trendstop',
      ],
    },
    en: {
      ...globalKeywords.trends,
      christineBoland: ['christine-boland'],
      edited: ['edited'],
      trendUnion: ['lidewij-edelkoort'],
      fashionSnoops: ['fashion-snoops'],
      peclers: ['peclers-paris'],
      trendstop: ['trendstop'],
    },
    es: {
      ...globalKeywords.trends,
      womenswear: ['moda-mujer', 'mujer', ...globalKeywords.trends.womenswear],
      menswear: ['hombre', 'moda-hombre', ...globalKeywords.trends.menswear],
      kids: ['kids', 'ninos', 'moda-ninos'],
      footwear: ['calzado', 'sneakers'],
      denim: ['vaqueros', 'moda-vaquera', ...globalKeywords.trends.denim],
      trends: [
        'tendencias',
        'lidewij-edelkoort',
        'fashion-snoops',
        'trendstop',
        'edited',
        'peclers-paris',
      ],
      colours: ['pantone', 'colores', 'tejidos'],
      parade: ['desfiles', 'desfile'],
    },
    fr: {
      ...globalKeywords.trends,
      season: ['collection-croisiere', ...globalKeywords.trends.season],
      womenswear: ['womenswearcatwalkseason', 'fashion-week'],
      menswear: ['mode-homme', ...globalKeywords.trends.menswear],
      kids: ['enfants'],
      footwear: ['chaussure', ...globalKeywords.trends.footwear],
      trends: [
        'tendances',
        'trends',
        'lidewij-edelkoort',
        'fashion-snoops',
        'trendstop',
        'edited',
        'peclers-paris',
      ],
      colours: ['pantone', 'couleurs'],
    },
    it: {
      ...globalKeywords.trends,
      season: ['ss21', ...globalKeywords.trends.season],
      womenswear: [
        'moda-donna',
        'pitti-filati',
        ...globalKeywords.trends.womenswear,
      ],
      menswear: [
        'moda-uomo',
        'milano-moda-uomo',
        'pitti-uomo',
        ...globalKeywords.trends.menswear,
      ],
      kids: ['pitti-bimbo', ...globalKeywords.trends.kids],
      footwear: ['calzature', ...globalKeywords.trends.footwear],
      denim: ['kingpins', ...globalKeywords.trends.denim],
      trends: [
        'tendenze',
        'trends',
        'lidewij-edelkoort',
        'fashion-snoops',
        'trendstop',
        'edited',
        'peclers-paris',
      ],
      colours: ['pantone', 'colori'],
    },
    ja: {
      ...globalKeywords.trends,
      season: ['2021年秋冬', '2022年春夏', ...globalKeywords.trends.season],
      womenswear: ['ウィメンズウェア', 'ウィメンズ'],
      menswear: ['メンズウェア', 'メンズ'],
      kids: ['子供服', 'キッズ'],
      footwear: ['スニーカー', 'フットウェア'],
      denim: ['デニム', 'ジーンズ'],
      trends: [],
      colours: [],
      trendstop: ['トレンドストップ', 'trendstop'],
    },
    nl: {
      ...globalKeywords.trends,
      womenswear: ['damesmode', ...globalKeywords.trends.womenswear],
      menswear: ['herenmode', 'mannenmode', ...globalKeywords.trends.menswear],
      kids: ['kidswear', 'kindermode'],
      footwear: ['schoenen', 'sneakers'],
      trends: ['trendstop', ...globalKeywords.trends.trends],
      colours: ['pantone', 'kleuren', 'kleurentrends'],
      christineBoland: ['christine-boland'],
      edited: ['edited'],
      trendUnion: ['lidewij-edelkoort'],
      fashionSnoops: ['fashion-snoops'],
      peclers: ['peclers-paris'],
    },
    pt: {
      ...globalKeywords.trends,
      season: [
        'primavera-verao-21-22',
        'resort-alto-verao-22',
        'outono-inverno-21',
        'ss21',
        ...globalKeywords.trends.season,
      ],
      womenswear: ['moda-feminina', 'desfiles-femininos'],
      menswear: ['moda-masculina', 'desfiles-masculinos'],
      kids: ['moda-infantil'],
      footwear: ['calcados', 'tenis', 'sandalias'],
      trends: [
        'tendencias',
        'edited',
        'trend-union',
        'wgsn',
        'peclers-paris',
        'lidewij-edelkoort',
        'fashion-snoops',
      ],
      colours: ['cores', 'pantone'],
      bodyFashion: ['moda-praia', 'beachwear', 'lingerie', 'moda-intima'],
    },
    ru: {
      ...globalKeywords.trends,
      womenswear: ['zhenskaya-odezhda', 'odezhda', 'womenswear'],
      menswear: ['muzhskaya-odezhda', 'odezhda', 'menswear'],
      kids: ['detskaya-odezhda', 'kidswear'],
      footwear: ['obuv'],
      denim: ['деним', 'dzhinsy', 'denim'],
      trends: ['trendy', ...globalKeywords.trends.trends],
      colours: ['pantone', 'tsvetnoj'],
      trendstop: ['trendstop'],
    },
    zh: {
      ...globalKeywords.trends,
      season: ['秋冬21', '2022春夏', ...globalKeywords.trends.season],
      womenswear: ['女装', '女装时装季'],
      menswear: ['男装', '男装时装季'],
      kids: ['童装'],
      footwear: ['运动鞋', '鞋履'],
      denim: ['牛仔时装', '牛仔裤'],
      trends: ['趋势', 'trendstop'],
      colours: ['彩通', '色彩', 'pantone'],
      beauty: ['美妆', '化妆品'],
    },
  },
  'fashion-week-web': {},
  education: {},
  executive: {
    en: {
      ...globalKeywords.executive,
    },
    ie: {
      ...globalKeywords.executive,
    },
    ca: {
      ...globalKeywords.executive,
    },
    nz: {
      ...globalKeywords.executive,
    },
    nl: {
      ...globalKeywords.executive,
      analysis: [
        'fashionunited-top-100-index',
        'index',
        'analyse',
      ],
      sustainability: [
        ...baseSustainabilityKeywords,
        'duurzaamheid',
        'circulariteit',
        'recycling',
        'regeneratieve-mode',
        'upcycling',
        'verhuur',
        'textielafval',
        'duurzame-textiel-innovaties',
        'reparatie',
      ],
      supplyChain: [
        'supply-chain',
        'logistiek',
        'productie',
        'sourcing',
        'arbeidsomstandigheden',
        'textielarbeiders',
        'toeleveringsketen',
      ],
      resale: [
        ...baseResaleKeywords,
        'tweedehands',
        'marktplaats',
      ],
      luxury: [
        ...baseLuxuryKeywords,
        'luxe',
        'valentino',
      ],
    },
    de: {
      ...globalKeywords.executive,
      analysis: [
        ...baseAnalysisKeywords,
        'analyse',
      ],
      sustainability: [
        ...baseSustainabilityKeywords,
        'nachhaltigkeit',
        'recycling',
        'upcycling',
        'kreislaufwirtshaft',
        'reparatur',
      ],
      supplyChain: [
        'supply-chain',
        'logistik',
        'produktion',
        'arbeiterrechte',
        'sourcing',
      ],
      resale: [
        ...baseResaleKeywords,
        'wiederverkauf',
        'momox',
      ],
      luxury: [
        ...baseLuxuryKeywords,
        'luxus',
      ],
    },
    fr: {
      ...globalKeywords.executive,
      analysis: [
        ...baseAnalysisKeywords,
        'analyse',
      ],
      sustainability: [
        ...baseSustainabilityKeywords,
        'durabilite',
        'durable',
        'mode-durable',
        'circularite',
        'mode-circulaire',
        'recyclage',
        'recycling',
        'loi-anti-gaspillage',
        'loi-climat-et-resilience',
      ],
      eCommerce: [
        'e-commerce',
      ],
      supplyChain: [
        'supply-chain',
        'logistique',
        'sourcing',
        'production',
        'travailleurs',
      ],
      resale: [
        ...baseResaleKeywords,
        'seconde-main',
        'vente-aux-encheres',
        'ebay',
      ],
      luxury: [
        ...baseLuxuryKeywords,
        'luxe',
        'miu-miu',
        'coperni',
        'schiaparelli',
      ],
    },
    es: {
      ...globalKeywords.executive,
      analysis: [
        ...baseAnalysisKeywords,
        'analisis',
      ],
      sustainability: [
        ...baseSustainabilityKeywords,
        'sostenibilidad',
        'circularidad',
        'moda-circular',
        'reciclaje',
        'moda-sostenible',
        'upcycling',
      ],
      eCommerce: [
        'e-commerce',
        'live-shopping',
      ],
      supplyChain: [
        'cadena-de-suministros',
        'trazabilidad',
        'logistica',
        'sourcing',
        'materiales-y-fibras',
        'nuevos-materiales',
        'distribucion',
        'omnicanalidad',
        'trabajo',
        'derechos-del-trabajador-textil',
        'trabajar-en-moda',
      ],
      resale: [
        ...baseResaleKeywords,
        'segunda-mano',
      ],
      luxury: [
        ...baseLuxuryKeywords,
        'lujo',
      ],
    },
    it: {
      ...globalKeywords.executive,
      analysis: [
        ...baseAnalysisKeywords,
        'analisi',
      ],
      sustainability: [
        ...baseSustainabilityKeywords,
        'sostenibilita',
        'circolarita',
        'riciclo',
      ],
      eCommerce: [
        'ecommerce',
      ],
      supplyChain: [
        'supply-chain',
        'logistica',
        'diritti-lavoratori',
      ],
      resale: [
        ...baseResaleKeywords,
        'seconda-mano',
        'second-hand',
      ],
      luxury: [
        ...baseLuxuryKeywords,
        'lusso',
      ],
      associations: [
        'camera-della-moda',
        'smi',
        'confcommercio',
        'confindustria-moda',
      ],
    },
    pt: {
      ...globalKeywords.executive,
      analysis: [
        ...baseAnalysisKeywords,
        'analises',
      ],
      sustainability: [
        ...baseSustainabilityKeywords,
        'sustentabilidade',
        'moda-circular',
        'reciclagem',
        'upcycling',
      ],
      eCommerce: [
        'e-commerce',
      ],
      supplyChain: [
        'logistica',
        'supply-chain',
        'sourcing',
        'centro-de-distribuicao',
      ],
      resale: [
        ...baseResaleKeywords,
        'segunda-mao',
        'brecho',
      ],
      luxury: [
        ...baseLuxuryKeywords,
        'luxo',
      ],
    },
  },
};

const slugs = {
  global: ['digital-fashion'],
  en: ['digital-fashion', 'beauty'],
  es: ['moda-digital'],
  fr: ['mode-digitale'],
  nl: ['digitale-mode'],
  zh: ['数位时尚', '美容'],
};

const getLanguage = (locale) => locale.slice(0, 2);

/**
 * Get keywords for local language.
 * @param {string} page news| trends | fashion-week-web | education | Executive
 * @param {string} locale en-US
 * @returns {Object} Keywords
 */
const getLocalKeywords = (page, locale) => {
  if (!keywords[page]) return null;
  const language = getLanguage(locale);

  return keywords[page][language] || globalKeywords[page];
};

const getLocalTags = (locale) => {
  const language = getLanguage(locale);
  return slugs[language] || slugs.global;
};

export {
  keywords,
  globalKeywords,
  getLocalKeywords,
  getLocalTags,
};
