import { getLocalKeywords } from './categoryKeywords';
import { allNewsCategoryIds, allCategoryIds } from './mainCategoryIds';

/**
 * Extracts all keywords from the tag categories.
 * @param {string} pageCategories
 * Array of Category objects: trendsCategories.categories
 * @returns {string[]} Array of keywords
 */
const getAllKeywords = (pageCategories) => {
  const array = [];

  pageCategories.forEach(({ variables: { tagSlugs } }) => {
    array.push(...tagSlugs);
  });

  return array;
};

const pages = (locale, pageId) => {
  const keywords = getLocalKeywords(pageId, locale);

  if (!keywords) return null;

  const page = {
    trends: {
      id: 'trends',
      categories: [
        {
          title: {
            id: 'product.season',
            value: null,
          },
          param: {
            id: 'category.tags.season.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.season,
          },
        },
        {
          title: {
            id: 'category.tags.womenswear',
            value: null,
          },
          param: {
            id: 'category.tags.womenswear.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.womenswear,
          },
        },
        {
          title: {
            id: 'category.tags.menswear',
            value: null,
          },
          param: {
            id: 'category.tags.menswear.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.menswear,
          },
        },
        {
          title: {
            id: 'category.tags.kids',
            value: null,
          },
          param: {
            id: 'category.tags.kids.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.kids,
          },
        },
        {
          title: {
            id: 'category.tags.footwear',
            value: null,
          },
          param: {
            id: 'category.tags.footwear.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.footwear,
          },
        },
        {
          title: {
            id: 'category.tags.denim',
            value: null,
          },
          param: {
            id: 'category.tags.denim.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.denim,
          },
        },
        {
          title: {
            id: 'trends',
            value: null,
          },
          param: {
            id: 'category.tags.trends.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.trends,
          },
        },
        {
          title: {
            id: 'category.tags.colours',
            value: null,
          },
          param: {
            id: 'category.tags.colours.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.colours,
          },
        },
        {
          title: {
            id: 'category.tags.bodyFashion',
            value: null,
          },
          param: {
            id: 'category.tags.bodyFashion.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.bodyFashion,
          },
        },
        {
          title: {
            id: 'category.tags.beauty',
            value: null,
          },
          param: {
            id: 'category.tags.beauty.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.beauty,
          },
        },
        {
          title: {
            id: 'category.tags.parade',
            value: null,
          },
          param: {
            id: 'category.tags.parade.param',
            value: null,
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.parade,
          },
        },
        {
          title: {
            id: null,
            value: 'Christine Boland',
          },
          param: {
            id: null,
            value: 'christine-boland',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.christineBoland,
          },
        },
        {
          title: {
            id: null,
            value: 'Edited',
          },
          param: {
            id: null,
            value: 'edited',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.edited,
          },
        },
        {
          title: {
            id: null,
            value: 'Trend Union',
          },
          param: {
            id: null,
            value: 'trend-union',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.trendUnion,
          },
        },
        {
          title: {
            id: null,
            value: 'Fashion Snoops',
          },
          param: {
            id: null,
            value: 'fashion-snoops',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.fashionSnoops,
          },
        },
        {
          title: {
            id: null,
            value: 'Peclers Paris',
          },
          param: {
            id: null,
            value: 'peclers',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.peclers,
          },
        },
        {
          title: {
            id: null,
            value: 'Trendstop',
          },
          param: {
            id: null,
            value: 'trendstop',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.trendstop,
          },
        },
        {
          title: {
            id: null,
            value: 'Forecast',
          },
          param: {
            id: null,
            value: 'forecast',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.forecast,
          },
        },
        {
          title: {
            id: null,
            value: 'Item of the Week',
          },
          param: {
            id: null,
            value: 'item-of-the-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.itemOfTheWeek,
          },
        },
      ],
    },
    'fashion-week-web': {
      id: 'fashion-week-web',
      categories: [
        {
          title: {
            id: null,
            value: 'Paris Fashion Week',
          },
          param: {
            id: null,
            value: 'paris-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.parisFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Milan Fashion Week',
          },
          param: {
            id: null,
            value: 'milan-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.milanFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'New York Fashion Week',
          },
          param: {
            id: null,
            value: 'new-york-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.newYorkFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'London Fashion Week',
          },
          param: {
            id: null,
            value: 'london-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.londonFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Accra Fashion Week',
          },
          param: {
            id: null,
            value: 'accra-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.accraFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Amsterdam Fashion Week',
          },
          param: {
            id: null,
            value: 'amsterdam-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.amsterdamFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Arab Fashion Week',
          },
          param: {
            id: null,
            value: 'arab-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.arabFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Berlin Fashion Week',
          },
          param: {
            id: null,
            value: 'berlin-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.berlinFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'China Fashion Week',
          },
          param: {
            id: null,
            value: 'china-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.chinaFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Copenhagen Fashion Week',
          },
          param: {
            id: null,
            value: 'copenhagen-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.copenhagenFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Fashion Revolution Week',
          },
          param: {
            id: null,
            value: 'fashion-revolution-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.fashionRevolutionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Frankfurt Fashion Week',
          },
          param: {
            id: null,
            value: 'frankfurt-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.frankfurtFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Graduate Fashion week',
          },
          param: {
            id: null,
            value: 'graduate-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.graduateFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Lakme Fashion Week',
          },
          param: {
            id: null,
            value: 'lakme-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.lakmeFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Los Angeles Fashion Week',
          },
          param: {
            id: null,
            value: 'los-angeles-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.losAngelesFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Madrid Fashion Week',
          },
          param: {
            id: null,
            value: 'madrid-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.madridFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Moscow Fashion Week',
          },
          param: {
            id: null,
            value: 'moscow-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.moscowFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Sao Paulo Fashion Week',
          },
          param: {
            id: null,
            value: 'sao-paulo-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.saoPauloFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Seoul Fashion Week',
          },
          param: {
            id: null,
            value: 'seoul-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.seoulFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Shanghai Fashion Week',
          },
          param: {
            id: null,
            value: 'shanghai-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.shanghaiFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Stockholm Fashion Week',
          },
          param: {
            id: null,
            value: 'stockholm-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.stockholmFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Taipei Fashion Week',
          },
          param: {
            id: null,
            value: 'taipei-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.taipeiFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Tokyo Fashion Week',
          },
          param: {
            id: null,
            value: 'tokyo-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.tokyoFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'Vegan Fashion Week',
          },
          param: {
            id: null,
            value: 'vegan-fashion-week',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.veganFashionWeek,
          },
        },
        {
          title: {
            id: null,
            value: 'More Fashion Weeks',
          },
          param: {
            id: null,
            value: 'more-fashion-weeks',
          },
          variables: {
            categoryIds: allCategoryIds,
            tagSlugs: keywords.moreFashionWeek,
          },
        },
      ],
    },
    education: {
      id: 'education',
      categories: [
        {
          title: {
            id: null,
            value: 'Graduation',
          },
          param: {
            id: null,
            value: 'graduation',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.graduation,
          },
        },
        {
          title: {
            id: null,
            value: 'Work In Fashion',
          },
          param: {
            id: null,
            value: 'work-in-fashion',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.workInFashion,
          },
        },
        {
          title: {
            id: null,
            value: 'Sustainability',
          },
          param: {
            id: null,
            value: 'sustainability',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.sustainability,
          },
        },
        {
          title: {
            id: null,
            value: 'Influencers',
          },
          param: {
            id: null,
            value: 'influencers',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.influencers,
          },
        },
        {
          title: {
            id: null,
            value: 'Social Media',
          },
          param: {
            id: null,
            value: 'social-media',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.socialMedia,
          },
        },
        {
          title: {
            id: null,
            value: 'Diversity',
          },
          param: {
            id: null,
            value: 'diversity',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.diversity,
          },
        },
        {
          title: {
            id: null,
            value: 'Internship',
          },
          param: {
            id: null,
            value: 'internship',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.internship,
          },
        },
      ],
    },
    executive: {
      id: 'executive',
      categories: [
        {
          title: {
            id: 'analysis',
            value: 'Analysis',
          },
          param: {
            id: null,
            value: 'analysis',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.analysis,
          },
        },
        {
          title: {
            id: 'sustainability',
            value: 'Sustainability',
          },
          param: {
            id: null,
            value: 'sustainability',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.sustainability,
          },
        },
        {
          title: {
            id: 'resale',
            value: 'Resale',
          },
          param: {
            id: null,
            value: 'resale',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.resale,
          },
        },
        {
          title: {
            id: 'e-commerce',
            value: 'e-Commerce',
          },
          param: {
            id: null,
            value: 'e-commerce',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.eCommerce,
          },
        },
        {
          title: {
            id: null,
            value: 'Supply Chain',
          },
          param: {
            id: null,
            value: 'supply-chain',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.supplyChain,
          },
        },
        {
          title: {
            id: 'luxury',
            value: 'Luxury',
          },
          param: {
            id: null,
            value: 'luxury',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.luxury,
          },
        },
        {
          title: {
            id: 'verticals',
            value: 'Verticals',
          },
          param: {
            id: null,
            value: 'verticals',
          },
          variables: {
            categoryIds: allNewsCategoryIds,
            tagSlugs: keywords.verticals,
          },
        },
        ...(locale === 'it-IT'
          ? [{
            title: {
              id: null,
              value: 'Associazioni',
            },
            param: {
              id: null,
              value: 'associations',
            },
            variables: {
              categoryIds: allNewsCategoryIds,
              tagSlugs: keywords.associations,
            },
          }]
          : []
        ),
      ],
    },
  };

  return {
    ...page[pageId],
    getKeywords: () => getAllKeywords(page[pageId].categories),
  };
};

const getTranslatedCategory = (intl, messages, { id, value }) => (
  id ? intl.formatMessage(messages[id]) : value
);

/**
 * Get category data by query params.
 * @param {string} page pageId
 * @param {string} categoryTag Category name
 * @param {string} locale en-US
 * @param {*} intl useIntl()
 * @param {*} messages messages
 * @returns {{
 *  title: { id: string, value: string },
 *  param: { id: string, value: string },
 *  variables: { categoryIds: number[], tagSlugs: string[] }
 * }} Category object
 */
const getCategory = (page, categoryTag, locale, intl, messages) => {
  const categoryPage = pages(locale, page);

  return categoryPage ? categoryPage.categories.find(
    ({ param }) => {
      const translatedParameter = getTranslatedCategory(intl, messages, param);
      return translatedParameter === categoryTag;
    },
  ) : null;
};

const getTrendsCategories = (locale) => pages(locale, 'trends');
const getFashionWeekCategories = (locale) => pages(locale, 'fashion-week-web');
const getEducationCategories = (locale) => pages(locale, 'education');
const getExecutiveCategories = (locale) => pages(locale, 'executive');

export {
  getTranslatedCategory,
  getCategory,
  getTrendsCategories,
  getFashionWeekCategories,
  getEducationCategories,
  getExecutiveCategories,
};
